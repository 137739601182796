<template>
  <section id="daftar-pembahasan-dispute">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_belanja_id"
                  v-model="master_jenis_belanja_id"
                  required
                  name="master_jenis_belanja_id"
                  :options="referenceData.ref_jenis_belanja"
                  placeholder="Jenis Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col> -->
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_pengadaan_id"
                  v-model="master_jenis_pengadaan_id"
                  required
                  name="master_jenis_pengadaan_id"
                  :options="referenceData.ref_jenis_pengadaan"
                  placeholder="Kriteria Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_sumber_dana_id"
                  v-model="master_sumber_dana_id"
                  name="master_sumber_dana_id"
                  :options="referenceData.ref_sumber_dana"
                  placeholder="Sumber Dana"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length,
              mode: 'pages',
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'total_anggaran'">
                {{ Number(props.row.total_anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'nama_kegiatan'">
                <feather-icon
                  v-if="props.row.prioritas_nasional === 1"
                  class="text-warning"
                  icon="StarIcon"
                  size="14"
                />
                {{ props.row.nama_kegiatan }}
              </span>
              <span v-else-if="props.column.field === 'nama_app_infra'">
                <b-badge
                  variant="light-info"
                  class="text-wrap mb-50"
                >
                  {{ props.row.domain }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama_app_infra }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'wait_time'">
                {{ props.row.last_update | moment }}
              </span>
              <!-- Approval Asdep -->
              <div
                v-else-if="props.column.field === 'asdep_confirm'"
                class="d-flex justify-content-center"
              >
                <b-form-checkbox
                  v-if="userData.group_id === 53 && props.row.status_pembahasan !== 'Menunggu Pembahasan'"
                  v-model="props.row.asdep_confirm"
                  :name="`approve-asdep-${props.row.id}`"
                  switch
                  inline
                  @change="saveApprovalDirektur('asdep', props.row)"
                />
                <template
                  v-else
                >
                  <span
                    v-if="props.row.asdep_confirm"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-secondary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <!-- Approval Takel -->
              <div
                v-else-if="props.column.field === 'takel_confirm'"
                class="d-flex justify-content-center"
              >
                <b-form-checkbox
                  v-if="userData.group_id === 51 && props.row.status_pembahasan !== 'Menunggu Pembahasan'"
                  v-model="props.row.takel_confirm"
                  :name="`approve-takel-${props.row.id}`"
                  switch
                  inline
                  @change="saveApprovalDirektur('takel', props.row)"
                />
                <template
                  v-else
                >
                  <span
                    v-if="props.row.takel_confirm"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-secondary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <!-- Approval LAIP -->
              <div
                v-else-if="props.column.field === 'laip_confirm'"
                class="d-flex justify-content-center"
              >
                <b-form-checkbox
                  v-if="userData.group_id === 52 && props.row.status_pembahasan !== 'Menunggu Pembahasan'"
                  v-model="props.row.laip_confirm"
                  :name="`approve-laip-${props.row.id}`"
                  switch
                  inline
                  @change="saveApprovalDirektur('laip', props.row)"
                />
                <template
                  v-else
                >
                  <span
                    v-if="props.row.laip_confirm"
                    class="text-success"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="20"
                    />
                  </span>
                  <span
                    v-else
                    class="text-secondary"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                    />
                  </span>
                </template>
              </div>
              <span v-else-if="props.column.field === 'status_name'">
                <b-badge
                  :variant="statusVariant(props.row.status_name).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.status_name).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'status_pembahasan'">
                <b-button
                  :variant="resultVariant(props.row.status_pembahasan).class"
                  size="sm"
                  style="max-width:120px"
                >
                  {{ resultVariant(props.row.status_pembahasan).title }}
                </b-button>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-if="props.row.is_continue === 0 && userData.group_id === 70"
                  v-b-tooltip.hover
                  variant="primary"
                  size="sm"
                  class="m-25"
                  title="Input Hasil Pembahasan Dispute"
                  @click="openRekomendasi(props.row)"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="12"
                  /></b-button>
                <b-button
                  v-else-if="props.row.status_pembahasan !== 'Menunggu Pembahasan' && userData.group_id === 70"
                  v-b-tooltip.hover
                  variant="secondary"
                  size="sm"
                  class="m-25"
                  title="Hasil Pembahasan Dispute"
                  @click="openHasilDispute(props.row)"
                >
                  <feather-icon
                    icon="ListIcon"
                    size="12"
                  />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
          <p class="mx-1 my-0">
            Total <b>{{ totalRecords }}</b> Entries
          </p>
        </b-card>

      </b-col>
      <!-- post -->

    </b-row>

    <b-modal
      id="modal-rekomendasi"
      ref="modal-rekomendasi"
      hide-footer
      centered
      size="lg"
      title="Hasil Pembahasan Dispute Eselon 2"
    >
      <modul-rekomendasi-dispute
        :program-data="rekoDispute"
        @result="updateResult"
      />
    </b-modal>

    <b-modal
      id="modal-hasil-rekomendasi"
      ref="modal-hasil-rekomendasi"
      hide-footer
      centered
      size="lg"
      title="Rekapitulasi Hasil Pembahasan Dispute Eselon 2"
    >
      <summary-rekomendasi-direktur
        :rekap-detail="listRekomendasi"
      />
    </b-modal>

  </section>

</template>

<script>
import store from '@/store'
import moment from 'moment-business-time'
import vSelect from 'vue-select'
import {
  BFormCheckbox, BRow, BBadge, BButton, BModal, BCol, BCard, BAlert,
  BFormGroup, BFormInput, VBTooltip, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import stColors from '@/vars/statusColors'

import SummaryRekomendasiDirektur from '../rekomendasi/SummaryRekomendasiDirektur.vue'
import ModulRekomendasiDispute from '../rekomendasi/ModulRekomendasiDispute.vue'

export default {
  components: {
    BAlert,
    BFormCheckbox,
    BButton,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    SummaryRekomendasiDirektur,
    ModulRekomendasiDispute,
    vSelect,
  },
  setup() {
    const {
      activityColors, activityColorsNew, clearanceColors, statusColors, verificationColors,
    } = stColors()

    const yearBudget = localStorage.getItem('tahunAnggaran')
    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: 'last_update',
          asc_desc: 'asc',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      yearBudget,
      activityColors,
      activityColorsNew,
      clearanceColors,
      statusColors,
      verificationColors,
      serverParams,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  filters: {
    moment(date) {
      const now = new Date()
      const today = moment(now).utc()
      const diff = today.workingDiff(date, 'days')
      return `${diff} Hari`
    },
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 70) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      alertText: '',
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      modalTitle: '',
      isLoading: false,
      columns: [],
      totalRecords: 0,
      selectPage: 1,
      rows: [],
      searchTerm: '',
      listRekomendasi: {},
      rekoDispute: {},
      master_data_instansi: '',
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
      prefix: '',
      nama_kegiatan: '',
      nama_app_infra: '',
      errorStat: false,
      errorMsg: '',
    }
  },
  created() {
    this.setupTanggal()
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('master_jenis_belanja_id')) {
      this.master_jenis_belanja_id = JSON.parse(localStorage.getItem('master_jenis_belanja_id'))
    }
    if (localStorage.getItem('master_jenis_pengadaan_id')) {
      this.master_jenis_pengadaan_id = JSON.parse(localStorage.getItem('master_jenis_pengadaan_id'))
    }
    if (localStorage.getItem('master_sumber_dana_id')) {
      this.master_sumber_dana_id = JSON.parse(localStorage.getItem('master_sumber_dana_id'))
    }
    if (localStorage.getItem('prefix')) {
      this.prefix = localStorage.getItem('prefix')
    }
    if (localStorage.getItem('nama_kegiatan')) {
      this.nama_kegiatan = localStorage.getItem('nama_kegiatan')
    }
    if (localStorage.getItem('nama_app_infra')) {
      this.nama_app_infra = localStorage.getItem('nama_app_infra')
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    this.setColumns()
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'bahasDispute' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'bahasDispute')
    this.getReference()
  },
  methods: {
    setupTanggal() {
      let holidays = []
      if (localStorage.getItem('holidays')) {
        holidays = JSON.parse(localStorage.getItem('holidays'))
      } else {
        this.$http.get('/tanggalan/holidays')
          .then(res => {
            const liburAll = res.data.data
            const libur = []

            Object.keys(liburAll).map(data => {
              libur.push(data)
              return data
            })
            holidays = libur
            localStorage.setItem('holidays', JSON.stringify(libur))
          })
      }
      moment.updateLocale('en', {
        holidays,
      })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    setColumns() {
      const columns = [
        {
          thClass: 'text-center',
          label: 'K/L',
          field: 'instansi_prefix',
          width: '175px',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama Instansi',
            filterValue: (this.prefix !== '') ? this.prefix : null,
            // trigger: 'enter',
          },
        },
        {
          thClass: 'text-center',
          label: 'Nama Kegiatan',
          field: 'nama_kegiatan',
          width: '175px',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama Program',
            filterValue: (this.nama_kegiatan !== '') ? this.nama_kegiatan : null,
            // trigger: 'enter',
          },
        },
        {
          thClass: 'text-center',
          label: 'Aplikasi / Infrastruktur',
          field: 'nama_app_infra',
          width: '175px',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama Aplikasi / Infrastruktur',
            filterValue: (this.nama_app_infra !== '') ? this.nama_app_infra : null,
            // trigger: 'enter',
          },
        },
        {
          thClass: 'text-center',
          label: 'Total Anggaran',
          field: 'total_anggaran',
          type: 'number',
        },
        {
          thClass: 'text-center',
          label: 'Perubahan Terakhir',
          field: 'last_update',
        },
        {
          thClass: 'text-center',
          label: 'Hari Dalam Antrian',
          field: 'wait_time',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Status',
          width: '140px',
          field: 'status_name',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Hasil Pembahasan',
          width: '150px',
          field: 'status_pembahasan',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Asdep SPBE',
          field: 'asdep_confirm',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Dir. Takel',
          field: 'takel_confirm',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Dir. LAIP',
          field: 'laip_confirm',
          sortable: false,
        },
      ]
      this.columns = columns
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    statusVariant(status) {
      if (this.statusColors[status]) {
        return this.statusColors[status]
      }
      return {
        title: status,
        class: 'light-info',
      }
    },
    resultVariant(status) {
      if (this.clearanceColors[status]) {
        return this.clearanceColors[status]
      }
      return {
        title: status,
        class: 'light-warning',
      }
    },
    globalFilter() {
      const params = []
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      if (this.master_jenis_belanja_id) {
        params.push({
          column: 'jenis_belanja_id',
          value: String(this.master_jenis_belanja_id.jenis_belanja_id),
        })
        localStorage.setItem('master_jenis_belanja_id', JSON.stringify(this.master_jenis_belanja_id))
      } else {
        localStorage.removeItem('master_jenis_belanja_id')
      }
      if (this.master_jenis_pengadaan_id) {
        params.push({
          column: 'jenis_pengadaan_id',
          value: String(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
        })
        localStorage.setItem('master_jenis_pengadaan_id', JSON.stringify(this.master_jenis_pengadaan_id))
      } else {
        localStorage.removeItem('master_jenis_pengadaan_id')
      }
      if (this.master_sumber_dana_id) {
        params.push({
          column: 'sumber_dana_id',
          value: String(this.master_sumber_dana_id.sumber_dana_id),
        })
        localStorage.setItem('master_sumber_dana_id', JSON.stringify(this.master_sumber_dana_id))
      } else {
        localStorage.removeItem('master_sumber_dana_id')
      }
      return params
    },
    doFilter() {
      const params = this.globalFilter()
      if (this.prefix !== '') {
        params.push({
          column: 'prefix',
          value: String(this.prefix),
        })
        localStorage.setItem('prefix', this.prefix)
      } else {
        localStorage.removeItem('prefix')
      }
      if (this.nama_kegiatan !== '') {
        params.push({
          column: 'nama_kegiatan',
          value: this.nama_kegiatan,
        })
        localStorage.setItem('nama_kegiatan', this.nama_kegiatan)
      } else {
        localStorage.removeItem('nama_kegiatan')
      }
      if (this.nama_app_infra !== '') {
        params.push({
          column: 'nama_app_infra',
          value: this.nama_app_infra,
        })
        localStorage.setItem('nama_app_infra', this.nama_app_infra)
      } else {
        localStorage.removeItem('nama_app_infra')
      }
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        let columName = param.field
        if (param.field === 'instansi_prefix') {
          columName = 'prefix'
        } else if (param.field === 'nama') {
          columName = 'nama_app_infra'
        }
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: columName,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = this.globalFilter()
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'instansi_prefix') {
          columName = 'prefix'
        } else if (key === 'nama') {
          columName = 'nama_app_infra'
        }
        this[columName] = valuez
        filterz.push({
          column: columName,
          value: valuez,
        })
        if (valuez !== '') {
          localStorage.setItem(columName, valuez)
        } else {
          localStorage.removeItem(columName)
        }
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/dispute/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            this.errorStat = false
            res.data.data.data.map(result => {
              // ketua tim , is_continue 1 : continue, 0 : belum, -1 : tidak dilanjutkan
              // direktur, is_continue 1 : continue, 0 : tidak dilanjutkan
              const rekolist = result
              rekolist.asdep_confirm = (result.asdep_confirm === 1)
              rekolist.takel_confirm = (result.takel_confirm === 1)
              rekolist.laip_confirm = (result.laip_confirm === 1)
              if (this.userData.group_id === 70) {
                if (result.is_continue === 1) {
                  rekolist.status_pembahasan = 'Dilanjutkan'
                } else if (result.is_continue === 0) {
                  rekolist.status_pembahasan = 'Menunggu Pembahasan'
                } else {
                  rekolist.status_pembahasan = 'Tidak Dilanjutkan'
                }
              } else if (result.is_continue === 1 && this.userData.group_id !== 70) {
                rekolist.status_pembahasan = 'Dilanjutkan'
              } else if (result.is_continue === 0 && this.userData.group_id !== 70) {
                rekolist.status_pembahasan = 'Tidak Dilanjutkan'
              } else {
                rekolist.status_pembahasan = 'Menunggu Pembahasan'
              }
              this.rows.push(rekolist)
              return true
            })
            // this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
    openRekomendasi(value) {
      this.rekoDispute = value
      this.$refs['modal-rekomendasi'].show()
    },
    openHasilDispute(appData) {
      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: appData.data_utama_id,
        domain: appData.domain,
        dispute_id: appData.dispute_id,
      }
      this.$http.get('/dispute/get-dispute', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            this.$refs['modal-hasil-rekomendasi'].show()
          }
        })
    },
    saveApprovalDirektur(who, appData) {
      const fieldName = `approve-${who}-${appData.id}`
      const field = document.querySelector(`input[name=${fieldName}]:checked`)

      if (field.checked) {
        const verif = {
          dispute_id: appData.dispute_id,
          data_utama_id: appData.data_utama_id,
          domain: appData.domain,
        }
        if (appData.domain === 'APLIKASI') {
          verif.aplikasi_id = appData.aplikasi_id
        } else {
          verif.infrastruktur_id = appData.infrastruktur_id
        }
        this.$http.post('/dispute/confirm', verif, {
          params: {
            token: localStorage.getItem('userToken'),
          },
        })
          .then(res => {
            if (res.data) {
              this.dataSaved = true
              this.doFilter()

              setTimeout(() => {
                this.dataSaved = false
              }, 1000)
            }
          })
      }
    },
    updateResult() {
      this.doFilter()
      this.$refs['modal-rekomendasi'].hide()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
